@import 'styles/variables';

.pagination-sort {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0 0.2rem 0 0;
    font-size: 0.8667rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .pageSize-select {
    width: 3.2rem;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 1rem 0 0.6667rem;

  li {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    font-size: 1.0667rem;
    line-height: 2.0667rem;
    margin: 0 0.4rem;
    &.active {
      background: #cecece;
      font-weight: 700;
    }
    & a {
      width: 100%;
      text-align: center;
    }
  }

  .disabled {
    > a {
      cursor: not-allowed;
    }
  }
  .selected {
    background: map-get($colors, 'blue');
    font-weight: 700;
    color: map-get($colors, 'light-white');
  }

  .previous,
  .next {
    a {
      font-size: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        color: #c2c9d1;
        fill: #c2c9d1;
        width: 20px;
        height: 20px;
      }
    }
  }

  .previous a {
    &::before {
      background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="hsl(212, 14%, 79%)" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M328 112L184 256l144 144"></path></svg>');
    }
  }

  .next a {
    &::before {
      background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="hsl(212, 14%, 79%)" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M184 112l144 144-144 144"></path></svg>');
    }
  }
}

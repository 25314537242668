@import 'swiper/swiper.scss';
@import 'swiper/components/navigation/navigation.scss';

.swiper-container {
  width: 100%;
  position: static;
  margin-left: 0;
  .swiper-button-next {
    transform: translateX(50%);
    right: 0;
  }
  .swiper-button-prev {
    transform: translateX(-50%);
    left: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: auto;
    height: auto;
    &.swiper-button-disabled {
      display: none;
    }
    &.custom-swiper-button {
      background: #ffffff;
      box-shadow: 0px 4px 24px rgba(28, 32, 36, 0.12);
      border-radius: 16px;
      padding: 12px;
      &::after {
        display: none;
      }
    }
  }
}
.swiper-slide {
  max-width: fit-content;
  a {
    display: block;
    max-width: fit-content;
  }
}

.container {
  display:flex;
  justify-content: center;
}

.comment {
  display: none;
}

ul.scroll {
  max-height: 5rem;
  overflow: auto;
}





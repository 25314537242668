@import 'styles/variables';
.invalid {
  .rdw-editor-main {
    border-color: #dc3545;
  }
  .rdw-editor-toolbar {
    border-color: #dc3545;
  }
}
.wysiwyg-title {
  padding: 0.3333rem 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: map-get($colors, 'gray');
}
.rdw-editor-main {
  background: #f5f5f5;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 0px 0px 0.6667rem 0.6667rem;
  height: 13.3333rem;
  line-height: 20px;
  padding: 0.5333rem 1.0667rem;
  overflow-y: scroll;
}
.rdw-editor-toolbar {
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 0.6667rem 0.6667rem 0px 0px;
  margin-bottom: 0;
  border-bottom: none;
  img {
    border-radius: 0;
  }
}

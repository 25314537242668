@import 'styles/variables';

.input {
  border: 1px solid #ccd5e0;
  border-radius: 2px;
  background-color: map-get($colors, 'white');
  margin-bottom: 0.4rem;
  height: 2.9333rem;
  padding: 12px 16px;
  &.noIcon {
    background-image: none !important;
  }
  &::placeholder {
    color: map-get($colors, 'gray');
    font-size: 0.9333rem;
    line-height: 1.3333rem;
  }
}

.passwordIcon {
  position: absolute;
  top: 0.8rem;
  right: 0.6667rem;
  cursor: pointer;
}

.phoneInput {
  width: 100% !important;
  border: 1px solid #ccd5e0 !important;
  border-radius: 2px !important;
  height: 2.9333rem !important;
  background-color: map-get($colors, 'white') !important;
  color: #1a202c !important;
  font-size: 14px !important;
  font-family: Raleway !important;
  font-style: normal !important;
  font-weight: normal !important;
  &.invalid {
    border-color: #dc3545 !important;
  }
}

.customLabel {
  padding: 0.3333rem 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: map-get($colors, 'gray');
}

.borderNone {
  background-color: transparent !important;
  border: none;
}

.inputTags {
  color: #718096;
  border: 1px solid #ccd5e0;
  padding-left: 5px;
}

// button.helpTooltips {
//   align-self: flex-end;
//   height: 44px;
//   width: 45px;
// }

// .customSelect {
//   flex-grow: 1;
// }

// .badge.bg-secondary {
//   background-color: hsl(0, 0%, 90%) !important;
//   color: #333333;
//   font-weight: 400;
// }

// .bootstrap-tagsinput .tag {
//   margin-right: 2px;
//   color: #718096 !important;
// }

// button.btn.btnDelete {
//   color: #718096;
//   border: 1px solid #ccd5e0;
//   font-size: 16px;
//   border-left: 1px solid #fff;
//   font-weight: 600;
// }

.bgAccordion:hover {
    background-color: #b7d4ed66 !important;
}

.bgAccordion {
    box-shadow: none !important;
}

.desc {
    white-space: pre-wrap;
    width: 70vw;
    overflow: visible; 
    line-height: 28px
}
@import 'styles/variables';

.custom-toggle {
  border: none;
  background: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  padding: 0;
  color: map-get($colors, 'dark_gray');
  &::after {
    display: none !important;
  }
}

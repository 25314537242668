.menu {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 5;
  padding: 5px;
  background: #fff;
  border: 1px solid #d2dae4;
  max-height: 40vh;
  overflow-y: scroll;

  .filter {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.show {
  display: block;
}

.invalid {
  border: 1px solid #dc3545 !important;
}

.dropdown {
  position: relative;

  .input {
    flex: 1 1 100%;
    min-height: 40px;
    border: 1px solid #d2dae4;
    padding: 0 16px;
    font-size: 14px;
    display: flex;
    padding: 5px 56px 5px 15px;
    align-items: center;
    flex-wrap: wrap;
  }

  .toggle {
    position: absolute;
    right: 10px;
    top: calc(50% - 8px);
    cursor: pointer;
  }

  .clearAll {
    position: absolute;
    right: 35px;
    top: calc(50% - 8px);
    cursor: pointer;
  }

  .placeholder {
    color: #718096;
  }

  .choosen {
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin: 2px;
    min-width: 0px;
    box-sizing: border-box;
    max-width: 275px;

    & > span:first-child {
      border-radius: 2px;
      color: rgb(51, 51, 51);
      font-size: 85%;
      overflow: hidden;
      padding: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
    }

    &_close {
      cursor: pointer;
      > span {
        display: flex;
      }
    }
  }
}

.menu_item {
  &_row {
    display: flex;
    justify-content: space-between;
    padding: 10px 7px;
    color: #718096;
    &:hover {
      background: #d2dae4;
    }
  }
  &_toggle {
    cursor: pointer;
    margin-left: 10px;
  }
  &_menu {
    overflow: hidden;
  }
  &_checkbox {
    margin: 0 7px 0 0;
  }
}

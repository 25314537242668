@import 'styles/variables';

.TitleWithLogo {
  min-width: 6rem;
  margin: auto;
  &:hover {
    text-decoration: none;
    color: map-get($colors, 'main_color');
  }

  &_title {
    color: map-get($colors, 'main_color');
    font-size: 1.3333rem;
    line-height: 150%;
    font-weight: 700;
  }
}

@import 'styles/variables';

.mainContainer {
  width: 100%;
  display: flex;
  height: fit-content;
  margin: 0.4rem 0.3rem;
}

.fileName {
  background-color: #f5f5f5;
  width: 100%;
  border: 1px dashed rgba(0, 0, 0, 0.09);
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .thumb {
    display: inline-flex;
    margin-right: 0.5333rem;
    width: 6.6667rem;
    height: 6.6667rem;
    position: relative;
    justify-content: center;
    &:hover {
      > .thumbBackdrop {
        opacity: 1;
      }
    }
    .thumbInner {
      display: flex;
      border-radius: 0.5333rem;
      min-width: 0px;
      overflow: hidden;
      justify-content: center;
    }
    .thumbBackdrop {
      .thumbIcon {
        cursor: pointer;
        padding: 0.25rem;
        background: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(7px);
        border-radius: 30px;
        margin-left: 8px;
      }
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: linear-gradient(
        180deg,
        rgba(25, 25, 25, 0) 0%,
        rgba(33, 33, 33, 0.75) 100%
      );
      border-radius: 0px 0px 8px 8px;
      opacity: 0;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3.125rem;
      text-align: right;
      padding: 0.5rem;
      transition: opacity 300ms linear;
    }
  }
}

.dropzoneLabel {
  color: #5b6bd5;
  font-weight: 600;
  font-size: 0.9333rem;
  line-height: 1.3333rem;
  cursor: pointer;
  &:hover {
    color: map-get($colors, 'dark_indigo');
  }
}

.mainLabel {
  padding: 0.3333rem 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #718096;
}

.dropzoneFiles {
  list-style-type: circle;
  padding-left: 20px;
  margin: 10px 0;
}

.dropzoneDescription {
  color: map-get($colors, 'gray');
  font-size: 0.9333rem;
  line-height: 1.3333rem;
}

.img {
  display: block;
  width: auto;
  height: 100%;
}

.dropzone {
  // background: rgba(0, 0, 0, 0.04);
  border: 1px dashed rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  min-height: 13rem;
  display: flex;
  padding: 0.6667rem;
  flex-direction: column;
}

.userInfo {
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 5px;
  max-width: 230px;
  .dropdownMenu {
    padding: 0 !important;
    border-radius: 0.1333rem !important;
    background: #ffffff;
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
      0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    border: none !important;
  }
}

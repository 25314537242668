@import 'styles/variables';

.image_container {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  img {
    pointer-events: none;
  }
}

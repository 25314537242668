.comment_form {
  width: 100%;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sendImage {
    width: 28px;
    height: 24px;
    background: url('./../../assets/images/send.png');
    background-size: cover;
    margin-bottom: 0.4rem;
    cursor: pointer;
    margin-left: 24px;
  }
}

.title {
  font-size: 16px;
  width: 100%;
  margin: 10px 0 16px 0;
}

.showAll {
  cursor: pointer;
  text-align: center;
}

.content {
  .noComment {
    display: flex;
    align-items: center;
    .commentImg {
      width: 56px;
      height: 56px;
      background: url('./../../assets/images/comment.png');
      margin-right: 16px;
    }
    > p {
      font-size: 12px;
    }
  }
  .withComment {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      .showWhenHovered {
        display: block;
      }
    }
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      margin-right: 24px;
    }
  }
  .showWhenHovered {
    display: none;
  }
}

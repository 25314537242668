@import 'styles/variables';

.tagsInput {
  display: flex;
  position: relative;
  border: 1px solid #ccd5e0;
  border-radius: 2px;
  background-color: map-get($colors, 'white');
  min-height: 2.9333rem;
  padding: 5px 16px;
  align-items: center;
  border-right: none;
  &.noIcon {
    background-image: none !important;
  }
  &::placeholder {
    color: map-get($colors, 'gray');
    font-size: 0.9333rem;
    line-height: 1.3333rem;
  }
}

.appendCloseBtn {
  border: 1px solid #ccd5e0;
  border-left: none;
  border-radius: 2px;
  align-items: center;
  margin-left: -1px;
  min-width: 2px;
  display: flex;
  padding: 5px;
}

.customLabel {
  padding: 0.3333rem 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: map-get($colors, 'gray');
}

.borderNone {
  background-color: transparent !important;
  border: none;
}

.tagsItem {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin: 2px;
  min-width: 0;
  // max-width: 18.3333rem;
  height: 1.7333rem;
  .text {
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    overflow: hidden;
    padding: 3px;
    padding-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .closeBtn {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    &:hover {
      background-color: #ffbdad;
      color: #de350b;
    }
  }
}

@import 'styles/variables';
$navbarHeight: 3.25rem;

.NavHeader {
  width: 100%;
  display: flex;
  align-items: center;

  .NavHeader__link {
    position: relative;
    &.active {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        border-radius: 4px 4px 0 0;
        transform: rotate(180deg);
        background-color: map-get($colors, 'indigo_2');
      }
      font-weight: 700;
    }
    display: inline-block;
    padding: 16.95px 0;
    font-size: 0.9333rem;
    line-height: 1.3333rem;
    color: map-get($colors, 'dark_gray');
    margin-right: 1.6rem;
    &:hover {
      text-decoration: none;
      outline: none;
    }
    &:active {
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }
}

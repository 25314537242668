.main_layout {
  min-height: 100vh;
  min-width: 100vh;
  display: grid;
  background-color: #f9f9f9;
  grid-template-areas:
    '. header .'
    '. content .';
  grid-template-columns: minmax(0, 0.3fr) minmax(0, 4fr) minmax(0, 0.3fr);
  grid-template-rows: 3.7333rem 1fr;
  &::after,
  &::before {
    content: '';
    background: #ffffff;
  }
}

.header {
  background: #ffffff;
  grid-area: header;
  min-height: 3.8rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_content {
  margin-top: 1.0667rem;
  grid-area: content;
}

@import 'styles/variables';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');
@import 'styles/main';

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  font-weight: normal;
  color: map-get($colors, 'main_color');
}

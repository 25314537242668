.custom-toggle {
  border: none;
  background: none;
  font-size: 0.9333rem;
  line-height: 1.34rem;
  padding: 0;
  color: #201f1e;
  &::before {
    display: none !important;
  }
}

@import 'styles/variables';

.active {
  background-color: #ccd5e0;
}

.search-bar {
  background-color: transparent;
  display: flex;
  min-height: 2.9333rem;
  align-items: stretch;
  border: 1px solid #ccd5e0;
  border-radius: 40px;
  margin-bottom: 20px;
  &_input {
    display: flex;
    align-items: center;
    width: 70%;
    margin-left: 1.7333rem;

    > div input {
      width: 100%;
      margin-left: 1rem;
      outline: none !important;
      border: none !important;
      background-color: transparent;
      height: 100%;
      display: block;
      font-weight: 600;
      &::placeholder {
        font-weight: 600;
        font-size: 0.9333rem;
        line-height: 1.3333rem;
        color: map-get($colors, 'gray');
      }
    }

    > div > div {
      overflow-x: scroll;
      max-height: 28vh;
      z-index: 4;
      width: 40vw;
      background: #fff;
      font-size: 16px;
      position: absolute;
      border-radius: 5px;
      margin-top: 8px;

      > div {
        font-weight: 600;
        padding: 0.5rem 0 0.5rem 3rem;
        cursor: pointer;
        &:hover {
          background-color: #ccd5e0;
        }
      }
    }
  }
  &_chkbox {
    width: 30%;
    height: 100;
    margin-left: -1px;
    box-sizing: border-box;
    border-left: 1px solid #ccd5e0;
    .search_btn {
      height: 100%;
      > button {
        + div {
          padding: 0;
        }
        justify-content: space-between;
        color: map-get($colors, 'main_color') !important;
        height: 100%;
        width: 100%;
        background-color: transparent !important;
        border: none;
        &:hover,
        &:active,
        &:focus {
          background-color: transparent !important;
          border: none !important;
          color: map-get($colors, 'main_color') !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
    > label {
      font-weight: 600;
      font-size: 0.9333rem;
      line-height: 1.3333rem;
      margin-bottom: 0;
      span {
        cursor: pointer;
        padding: 0.6rem 1.0667rem;
      }
      input[type='checkbox'] {
        display: none;
        &:checked + span {
          background-color: map-get($colors, 'indigo_2');
          color: map-get($colors, 'light_indigo');
          border-radius: 1.3333rem;
        }
      }
    }
  }
}

.icon {
  display: inline-block;
  border: none;
  outline: none;
  background: none;
  padding: 0 5px;
  &:hover {
    opacity: 0.8;
  }
  &.backdrop {
    background: #f5f5f5;
    padding: 0.4rem;
    border-radius: 50%;
  }
}

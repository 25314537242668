@import 'styles/variables';

.custom-toggle {
  border: none;
  background: none;
  padding: 0;
  color: map-get($colors, 'main_color');
  font-weight: 500;
  font-size: 0.9333rem;
  line-height: 1.3333rem;
  padding: 8px;
  min-width: 230px;
  text-align: left;
  cursor: default !important;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: map-get($colors, 'light_indigo');
  }
  &::after {
    display: none !important;
  }
}

.dropdownMenu {
  padding: 0 !important;
  border-radius: 0.1333rem !important;
  background: #ffffff;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
    0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  border: none !important;
}

@import 'styles/variables';

.ComponentsNavBar {
  border-bottom: 2px solid map-get($colors, 'light_gray');
  display: flex;
  .ComponentsNavBar__link {
    margin-right: 1.6rem;
    font-size: 0.9333rem;
    line-height: 1.3333rem;
    display: block;
    padding: 0.6667rem 0;
    color: map-get($colors, 'main_color');
    margin-bottom: -2px;
    &.active {
      font-weight: 600;
      border-bottom: 2px solid map-get($colors, 'indigo_2');
    }
    &:hover {
      text-decoration: none;
      outline: none;
    }
    &:active {
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }
}

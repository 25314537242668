@import 'styles/variables';

.dropdownItem {
  background: #ffffff;
  color: map-get($colors, 'main_color');
  font-weight: 500;
  font-size: 0.9333rem;
  line-height: 1.3333rem;
  padding: 8px;
  &:hover {
    background-color: map-get($colors, 'light_indigo');
    cursor: pointer;
  }
  &:active,
  &:focus {
    color: map-get($colors, 'main_color');
  }
}

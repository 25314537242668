@import 'styles/variables';

.dp-label {
  padding: 0.3333rem 0px;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgb(113, 128, 150);
}

.react-datepicker {
  &-wrapper {
    width: 100%;
  }
  &__input-container {
    input {
      width: 100%;
      box-sizing: border-box !important;
      border: 1px solid #ccd5e0;
      border-radius: 2px;
      background-color: map-get($colors, 'white');
      margin-bottom: 0.4rem;
      height: 2.9333rem;
      padding: 12px 16px;
    }
  }
}

.react-datepicker-icon {
  position: absolute;
  right: 0;
  padding: 12px 7px;
  margin-left: auto;
  cursor: pointer;
}

.react-datepicker-custom-input {
  border: 1px solid #ccd5e0;
  border-radius: 2px;
  background-color: map-get($colors, 'white');
  margin-bottom: 0.4rem;
  height: 2.9333rem;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  &.invalid {
    border-color: #dc3545;
  }
}

.react-datepicker__close-icon {
  padding: 0px 6px 2px 0px;
  &::after {
    cursor: pointer;
    color: #718096 !important;
    background-color: transparent !important;
    border-radius: 0;
    height: auto !important;
    width: auto !important;
    padding: 0;
    font-size: 25px !important;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: '\00d7';
    padding-bottom: 10px !important;
  }
}

.react-datepicker-popper {
  z-index: 500 !important;
  .react-datepicker {
    font-size: 16px;
    border: 1px solid map-get($colors, 'grey');
    border-radius: 0;
    box-shadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18);
    padding: 15px;

    &__month-container {
      width: 100%;
      max-width: 268px;
    }
    &__current-month {
      font-weight: 400;
      font-size: 1.2rem;
      margin: 10px 0 10px 10px;
      text-align: start;
      text-transform: capitalize;
    }
    &__header {
      border: none;
      background: #ffffff;
      padding: 0;
      margin-bottom: 0 !important;
    }

    &__triangle {
      display: none;
    }
    &__navigation {
      width: 25px;
      height: 25px;
      top: 34px;
      border: none;
      &--previous {
        left: unset !important;
        top: 20px;
        right: 50px;
        background: url(../../assets/icon/arrow-up-icon.svg) no-repeat center;
      }
      &--next {
        top: 20px;
        right: 20px;
        left: unset !important;
        background: url(../../assets/icon/arrow-down-icon.svg) no-repeat center;
      }
    }
    &__day,
    &__day-name {
      padding: 0 !important;
      margin: 0 !important;
      width: 35px;
      height: 35px;
      line-height: 35px;
    }

    &__day {
      &--in-selecting-range,
      &--in-range {
        background: #ecf4ff;
        color: currentColor;
        border-radius: 0;
      }

      &--range-end,
      &--range-start,
      &--selecting-range-end,
      &--selecting-range-start,
      &--in-selecting-range:hover,
      &--selected,
      &--selected:hover {
        background: #002f6c !important;
        color: white;
        border-radius: 5px;
      }
    }
  }
}

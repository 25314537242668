.toggle {
  &::before,
  &::after {
    display: none !important;
  }
}

.dropdownMenu {
  padding: 0 !important;
  border-radius: 0.1333rem !important;
  background: #ffffff;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
    0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  border: none !important;
  display: grid !important;
  grid-template-columns: auto auto auto;
}
